import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';

const ICON_ID = 'warranty';
const namespace = 'ui-pdp-icon ui-pdp-icon--protected';

const IconWarranty = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="14"
    viewBox="0 0 12 14"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconWarranty.propTypes = {
  className: string,
};

IconWarranty.defaultProps = {
  className: null,
};

IconWarranty.ICON_ID = ICON_ID;

export default React.memo(IconWarranty);
export { IconWarranty };
